import React, { useEffect, useRef, useState } from "react";

const LandingPage = () => {
  const innerDivRef = useRef(null);
  const [fontSize, setFontSize] = useState("10rem");
  const [dots, setDots] = useState("");

  useEffect(() => {
    const adjustFontSize = () => {
      const innerDivWidth = innerDivRef.current.offsetWidth;
      if (innerDivWidth > window.innerWidth * 0.7) {
        setFontSize("5rem");
      } else {
        setFontSize("10rem");
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500); // Change the interval time if you want the animation to be faster or slower

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div ref={innerDivRef} style={{ textAlign: "center", maxWidth: "90vw" }}>
        <h1
          className="hover-effect"
          style={{ color: "white", fontSize: fontSize, transition: "all 0.3s" }}
        >
          leksa
          <span
            style={{
              fontSize: "0.3em",
              display: "inline-block",
            }}
          >
            .io
          </span>
        </h1>

        <h4 style={{ color: "white" }}>coming soon{dots}</h4>
      </div>
      <style jsx>{`
        .hover-effect:hover {
          transform: scale(1.2);
          color: black !important;
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
